import ReportPage from "@/components/ReportPage.vue";
import AppTabsLinks from "@/components/AppTabsLinks.vue";
export default {
  name: "Home",
  data() {
    return {
      tabs: [{
        title: "Настройки аккаунта",
        route: "/profile/settings"
      }, {
        title: "Тариф и оплата",
        route: "/profile/plan"
      }, {
        title: "История запросов",
        route: "/profile/history"
      }, {
        title: "Партнерская программа",
        route: "/profile/partner"
      }]
    };
  },
  components: {
    ReportPage,
    AppTabsLinks
  }
};
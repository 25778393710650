export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    noMarginTop: {
      type: Boolean,
      default: false
    }
  }
};
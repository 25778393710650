var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tabs__block",
    class: {
      noMarginTop: _vm.noMarginTop
    }
  }, [_c('ul', {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (tab, i) {
    return _c('li', {
      key: i,
      staticClass: "tab-wrapper"
    }, [_c('router-link', {
      staticClass: "tab",
      attrs: {
        "to": tab.route,
        "active-class": "tab-active"
      }
    }, [_vm._v(" " + _vm._s(tab.title) + " ")])], 1);
  }), 0), _vm.$slots.default ? _c('div', {
    staticClass: "right-block"
  }, [_vm._t("default")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('report-page', [_c('app-tabs-links', {
    attrs: {
      "tabs": _vm.tabs,
      "no-margin-top": ""
    }
  }), _c('div', {
    staticClass: "section mt32"
  }, [_c('router-view')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };